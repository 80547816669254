// ** Reducers Imports 
import layout from "./layout";
import navbar from "./navbar";
import auth from './slices/auth.Slice';
import brand from './slices/brandSlice';
import category from './slices/categorySlice';
import product from './slices/productSlice';
import payment from './slices/paymentSlice';
import order from './slices/orderSlice';
import banner from './slices/bannerSlice';
import foodBrand from './slices/foodbrandSlice';
import foodCategory from './slices/foodCategorySlice';
import coupon from './slices/couponSlice';
import voucher from './slices/voucherSlice';
import digitalCode from './slices/digitalCodeSlice';
import notification from './slices/notificationSlice';
import user from './slices/userSlice';
import outletExplore from './slices/outletExploreSlice';
import dashboard from "./slices/dashboard.Slice";
import multiBrand from "./slices/multiBrandSlice"

const rootReducer = {
    navbar,
    layout,
    auth,
    brand,
    category,
    product,
    payment,
    order,
    banner,
    foodBrand,
    foodCategory,
    coupon,
    voucher,
    digitalCode,
    notification,
    user,
    outletExplore,
    dashboard,
    multiBrand
}

export default rootReducer;
